import * as React from "react"
import { graphql } from 'gatsby'
import { PageWrapper } from "../components/PageWrapper"
import { Section } from "../components/Section"
import { PostGrid } from "../components/PostGrid"

// markup
const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  return (
    <PageWrapper>
      <Section>
        <PostGrid />
      </Section>
    </PageWrapper>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            slug
            keywords
            thumbnail
            title
          }
        }
      }
    }
  }
`