import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import {FadeIn} from './FadeIn';

const BasePost = ({ type, title, thumbnail, className }) => {
    return (
        <FadeIn>
            <a
                href="/blog/better-conversion"
                className={classNames(
                    className,
                    type
                )}
            >
                <div className="line" />
                {type === 'lg' && (
                    <img src={thumbnail} />
                )}
                <h2>
                    {title}
                </h2>
                {type === 'sm' && (
                    <p className="font-size sm">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur deserunt ex blanditiis, odio laboriosam recusandae quibusdam minima quia delectus facilis quos distinctio illo voluptates unde ut voluptas? Suscipit, facere non!
                    </p>
                )}
            </a>
        </FadeIn>
    )
}

export const Post = styled(BasePost)`
    position: relative;
    text-decoration: none;
    color: #000;
    display: block;

    p {
        margin: 0;
        position: relative;
    }

    h2 {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    &:hover {
        h2 {
           text-decoration: underline;
        }
    }

    &.sm {
    }

    &.lg {
        img {
            width: 100%;
            position: relative;
            margin-bottom: 1rem;
        }
    }

    .line {
        width: 100%;
        height: 2px;
        background-color: #000;
        margin: 1.5rem 0;
    }
`