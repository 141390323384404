import React from 'react';
import styled from 'styled-components';
import { Post } from './Post';

const BasePostGrid = ({ className }) => {
    return (
      <div className={className}>
          <div>
            <Post type="sm" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam." />
            <Post type="lg" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam." thumbnail="https://images.unsplash.com/photo-1590004953392-5aba2e72269a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" />
            <Post type="sm" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam" />
          </div>
          <div>
            <Post type="sm" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam" />
            <Post type="sm" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam." />
            <Post type="lg" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam" thumbnail="https://images.unsplash.com/photo-1637184488314-dcc87aa8bba8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=991&q=80" />
          </div>
          <div>
            <Post  type="sm" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"/>
            <Post type="lg" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam" thumbnail="https://images.unsplash.com/photo-1636237693155-9a57c81c6077?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" />
            <Post type="sm" title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam." />
          </div>
      </div>
    )
  }

export const PostGrid = styled(BasePostGrid)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;

    .line {
        width: 100%;
        height: 4px;
        background-color: #000;
        margin-top: 1.5rem;
    }
`;